import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";
import bg from "./bg.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Button, Dropdown, DropdownButton, Form, InputGroup } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";

const PING_AMOUNT = 4;
const PING_INTERVAL = 1000;

interface Region {
    region: string;
    az: string;
    country_code: string;
    city_code: string;
    browserPing?: number;
}

interface TracerouteNode {
    seq: number;
    ip_addr: string;
    host_name: string;
    ttl: number;
    node_type: string;
    rtt: string;
}
interface TracerouteResult {
    address: string;
    hostname: string;
    nodes: TracerouteNode[];
    status: string;
    probe_time: string;
}

interface PingResult {
    ping: number;
    address: string;
    success: number;
    errored: 0;
}

export const pingColor = (ping: number) => {
    if (ping < 0 || !Number.isFinite(ping)) {
        return "rgb(150, 150, 150)";
    }

    const mult = 125 - Math.round(ping / 1000 * 125);

    return `hsl(${mult}, 70%, 40%)`;
};

export const wait = (n: number) => new Promise(resolve => setTimeout(resolve, n));

export const pingRegion = (
    az: string,
    cb: (arg: number) => void,
    opts: any = { maxPings: 50, interval: 5, url: null },
) => {
    const { maxPings = 50, interval = 5, startDelay = 2000 } = opts;

    const proto = "wss:";
    const url = opts.url || `${proto}//ping-${az}.hostvds.com/socket`;

    return new Promise((resolve) => {
        const ws = new WebSocket(url);
        const pings: number[] = [];
        ws.onopen = async () => {
            for (const i in new Array(maxPings).fill(0)) {
                let sendTime = Date.now();
                let timedOut = true;
                let received = false;
                const start = Date.now();
                const date = start.toString().substring(12);
                ws.onmessage = (msg) => {
                    const now = Date.now();
                    if (msg.data !== date) {
                        return;
                    }
                    received = true;
                    timedOut = false;
                    pings.push(now - sendTime);

                    if (Number(i) % (interval as number) === 0) {
                        const avgLatency = Math.round(
                            pings.reduce((acc: number, item: number) => acc + item, 0) / pings.length
                        );

                        cb(avgLatency);
                    }
                };

                // setTimeout(() => {
                sendTime = Date.now();
                ws.send(date);

                while (!received && (Date.now() - start < 5000)) {
                    await wait(100);
                }

                if (timedOut) {
                    resolve(null);
                    break;
                }
            }

            ws.close();

            if (pings.length <= (maxPings / 2)) {
                cb(-3);
                resolve(null);
            }
        };

        ws.onerror = (err) => {
            console.warn(err);
            cb(-3);
            resolve(null);
        };
    });
};

const countryName = (code: string) => {
    try {
        const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
        return regionNames.of(code.toUpperCase());
    } catch (err) {
        console.warn(err);
        return code;
    }
};

const cityName = (code: string) => {
    if (code === "dallas") return "Dallas";
    if (code === "silicon-valley") return "Silicon Valley";
    if (code === "amsterdam") return "Amsterdam";
    if (code === "hong-kong") return "Hong Kong";
    if (code === "paris") return "Paris";
    if (code === "msk") return "Moscow";
    if (code === "hel") return "Helsinki";
    return code;
};

const SelectRegion = (props: {
    regions: Region[];
    selected: string;
    onSelect: (code: string) => void;
    browserPing: { [key: string]: number };
}) => {
    const selectedRegion = props.regions.find(r => r.az === props.selected);
    if (!selectedRegion) return <span />;

    const countryNameComponent = (region: Region) => {
        const ping = props.browserPing[region?.az ?? ""];
        return <span>
            <ReactCountryFlag
                svg
                countryCode={region.country_code}
                style={{
                    marginRight: "0.3em",
                    marginTop: "-3px",
                }}
            />
            {cityName(region.city_code) ?? ""}
            <span style={{ opacity: "0.45" }}>{" Browser ping: "}</span>
            {!ping && <span title="Ping to your location">
                <span>loading...</span>
            </span>}
            {ping && <span title="Ping to your location" style={{ color: pingColor(ping), transition: "0.1s" }}>
                <span>{ping}ms</span>
            </span>}
        </span>;
    };

    return (
        <Row>
            <Col>
                <DropdownButton variant="dark" id="region-select" title={countryNameComponent(selectedRegion)}>
                    {props.regions.filter(r => r.az !== selectedRegion.az).map(region => {
                        return (
                            <Dropdown.Item onClick={() => props.onSelect(region.az)} key={region.region}>
                                {countryNameComponent(region)}</Dropdown.Item>
                        );
                    })}
                </DropdownButton>
            </Col>
        </Row>
    );
};

function App() {
    const [regions, setRegions] = useState<Region[]>([]);
    const [loading, setLoading] = useState(true);
    const [pinging, setPinging] = useState(false);
    const [browserPings, _setBrowserPings] = useState<{ [key: string]: number }>({});
    const browserPingsRef = useRef<{ [key: string]: number }>({});
    const [host, setHost] = useState("");
    const [mode, setMode] = useState<"ICMP" | "TRACEROUTE">("ICMP");
    const [pingResult, setPingResult] = useState<PingResult>();
    const [tracerouteResult, setTracerouteResult] = useState<TracerouteResult>();
    const [error, setError] = useState<string>();
    const [selectedRegion, setSelectedRegion] = useState<string>();

    const testIp = process.env["REACT_APP_TEST_IP"];

    const setBrowserPings = (val: any) => {
        browserPingsRef.current = val;
        _setBrowserPings(val);
    };

    useEffect(() => {
        fetch(
            "/api/regions/",
            { mode: "no-cors" },
        ).then(res => res.json())
            .then(res => {
                res.sort((a: Region, b: Region) => {
                    if (a.country_code === "ru") return 1;
                    if (b.country_code === "ru") return -1;
                    return 0;
                });
                res = res.filter((r: any) => r.country_code !== "ru");
                setRegions(res);
                const currAz = window.location.hostname.match(/ping-(?<host>.+)\.hostvds.com/)?.groups?.host;
                console.log(currAz || res[0]?.az);
                setSelectedRegion(currAz || res[0]?.az);
            })
            .then(() => setLoading(false));
    }, []);

    useEffect(() => {
        const browserPingsLocal: { [key: string]: number } = {};

        const promises = regions.map(region => {
            pingRegion(region.az, (ping) => {
                browserPingsRef.current[region.az] = ping;

                setBrowserPings({ ...browserPingsRef.current });
            }, { startDelay: 0, maxPings: 30 });
        });
    }, [regions]);

    const icmpPing = () => {
        let url = `/op/ping?address=${host}&amount=${PING_AMOUNT}&interval=${PING_INTERVAL}`;
        if (location.href.includes("hostvds.com")) {
            url = `https://ping-${selectedRegion}.hostvds.com/op/ping?address=${host}&amount=${PING_AMOUNT}&interval=${PING_INTERVAL}`;
        }

        fetch(url, { method: "POST" })
            .then(res => res.json())
            .then(res => {
                setPingResult(res);
            }).finally(() => {
                setPinging(false);
            }).catch(err => {
                setError("Something went wrong...");
            });
    };

    const traceroute = () => {
        let url = `/op/traceroute?address=${host}&amount=${PING_AMOUNT}&interval=${PING_INTERVAL}`;
        if (location.href.includes("hostvds.com")) {
            url = `https://ping-${selectedRegion}.hostvds.com/op/traceroute?address=${host}&amount=${PING_AMOUNT}&interval=${PING_INTERVAL}`;
        }

        fetch(url, { method: "POST" })
            .then(res => res.json())
            .then(res => {
                setTracerouteResult(res);
            }).finally(() => {
                setPinging(false);
            }).catch((err) => {
                console.warn(err);
                setError("Something went wrong...");
            });
    };

    const ping = () => {
        setPingResult(undefined);
        setTracerouteResult(undefined);
        setError(undefined);
        setPinging(true);
        if (mode === "ICMP") return icmpPing();
        if (mode === "TRACEROUTE") return traceroute();
    };

    if (loading) {
        return <span>loading</span>;
    }

    return (
        <Container className="App" style={{ background: `url(${bg})`, minHeight: "100vh", height: "100%", backgroundSize: "cover" }}>
            <Row>
                <Col style={{ padding: "5em 0 0 0" }}>
                    <Row>
                        <Col>
                            <img src={logo} className="logo" />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <h1 style={{ color: "white" }}>Network Looking Glass</h1>
                        </Col>
                    </Row>
                    {selectedRegion && <Row className="card m-auto mt-4">
                        <Col>
                            <Row>
                                <Col>
                                    <h3>Server Location</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <SelectRegion
                                        onSelect={(region) => {
                                            setSelectedRegion(region);
                                            if (location.hostname.includes("hostvds.com")) {
                                                location.href = `https://ping-${region}.hostvds.com/`;
                                            }
                                        }}
                                        selected={selectedRegion}
                                        browserPing={browserPings}
                                        regions={regions} />
                                </Col>
                            </Row>
                            {testIp && <Row className="mt-2">
                                <Col>
                                    Test IPv4: <a onClick={(e) => {
                                        e.preventDefault();
                                        setHost(testIp ?? "");
                                    }} href="#">{testIp}</a>
                                </Col>
                            </Row>}
                            <Row className="mt-2">
                                <Col>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            onChange={(e) => setHost(e.target.value)}
                                            value={host}
                                            placeholder="Host or IP address"
                                            aria-label="Host or IP address"
                                        />
                                        <DropdownButton
                                            title={mode === "TRACEROUTE" ? "Traceroute" : "ICMP Ping"}
                                            align="end"
                                        >
                                            {mode !== "ICMP" && <Dropdown.Item onClick={() => setMode("ICMP")}>ICMP Ping</Dropdown.Item>}
                                            {mode !== "TRACEROUTE" && <Dropdown.Item onClick={() => setMode("TRACEROUTE")}>Traceroute</Dropdown.Item>}
                                        </DropdownButton>

                                        <Button onClick={ping} disabled={pinging || !host.length} variant="primary">
                                            {pinging ? "Running..." : "   Run   "}
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                            {(pingResult || tracerouteResult || pinging) && <Row
                                className="mt-2 p-2 bg-dark text-white"
                                style={{ fontFamily: "monospace" }}
                            >
                                {pinging && <Col><span>loading...</span></Col>}
                                {pingResult && <Col>
                                    {error && <span>{error}</span>}
                                    {pingResult.address &&
                                        <span><strong>{(pingResult?.success ?? 0) + (pingResult?.errored ?? 0)}</strong> packets transmitted, <strong>{pingResult.success}</strong> received, average time is <strong>{pingResult.ping}</strong>ms</span>}
                                </Col>}
                                {tracerouteResult && <Col>
                                    {error && <span>{error}</span>}
                                    {tracerouteResult && <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    traceroute to {tracerouteResult.hostname} ({tracerouteResult.address})
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            {tracerouteResult.nodes.map((node, i) => {
                                                                return (<Row key={i}>
                                                                    <Col sm={"auto"}>
                                                                        {node.seq}
                                                                    </Col>
                                                                    <Col>
                                                                        {node.host_name} ({node.ip_addr}) {node.rtt}
                                                                    </Col>
                                                                </Row>);
                                                            })}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>}
                                </Col>}
                            </Row>}
                        </Col>
                    </Row>}
                </Col>
            </Row>
        </Container>
    );
}

export default App;
